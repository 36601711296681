import {Button, Card, Col, Modal, Row} from "antd";
import {BranchesOutlined} from "@ant-design/icons";
import * as React from "react";
import {useContext} from "react";
import {UserContext} from "../../../providers/UserProvider";

const gutter = {xs: 8, sm: 16, md: 24, lg: 32}

export function DocProviderModal({visible, onCancel}) {
    const {iam} = useContext(UserContext);
    return (
        <Modal centered open={visible} className={"with-background"}
               onCancel={onCancel} width={700} footer={[]}>
            <div className="modal-background"></div>
            <div className="modal-icon"><BranchesOutlined/></div>
            <h2 style={{marginRight: 20, marginTop: -5}}>Add documentation provider</h2>
            <Card title={"Documentation"} style={{marginBottom: 0, maxHeight: "70vh", overflow: "auto"}}>
                <Row gutter={gutter}>
                    <Col span={8}>
                        <Button className="git-install" href={window._env_.CONFLUENCE_REDIRECT_URL.replace("YOUR_USER_BOUND_VALUE", iam._id)}>
                            <Row
                                type="flex"
                                justify="center"
                                align="middle"
                                style={{minHeight: '100%'}}
                            >
                                <Col>
                                    <img alt="confluence" src="/icons/conlfuence-logo.png" width={80}/>
                                </Col>
                            </Row>
                        </Button>
                    </Col>
                </Row>
            </Card>
        </Modal>)
}

import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useState, useRef} from "react";
import {UserContext} from "../../providers/UserProvider";
import BlockingModal from "../dashboard/elements/PermissionModal";


export default function ProviderRedirect() {
    const {backendService, iam, askReload} = useContext(UserContext);
    const navigate = useNavigate();

    const {provider, type} = useParams();
    const [loading, setLoading] = useState(false);
    const [outputStatus, setOutputStatus] = useState("loading");
    const [errorMessage, setErrorMessage] = useState({});
    const [searchParams] = useSearchParams()
    const hasRun = useRef(false);



    const createAccessRight = useCallback(async (values) => {
        setLoading(true);
        try{
            await backendService.addAccessRights(type, {
                id: provider,
                resourceName: provider,
                provider: provider,
                resourceDetails: {
                    ...paramsToObject(searchParams),
                    ...values,
                }
            })
            askReload(true);
            setOutputStatus("linked")
            navigate("/onboarding?scan=true");
        }catch(e){
            setOutputStatus("error");
            setErrorMessage(e?.response?.data)
        }
    }, [searchParams, type, navigate, provider, backendService, askReload])

    useEffect(() => {
        if (!iam || loading || hasRun.current){
            return
        }
        hasRun.current = true;
        createAccessRight({}).then()
    }, [provider, iam, loading, createAccessRight, searchParams])


    const params = paramsToObject(searchParams);
    if (params.error ) {
        return <BlockingModal title={"Error: " + params.error} content={params.error_description}  onCancel={()=>navigate("/onboarding")}></BlockingModal>
    }
    if(outputStatus === "error"){
        return <BlockingModal title={"Error: " + errorMessage.code} content={errorMessage.message} onCancel={()=>navigate("/onboarding")} ></BlockingModal>
    }
    return <></>

}

function paramsToObject(entries) {
    const result = {}
    for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
}
import {useState} from "react";
import {Button, Card, Col, Flex, Modal, Radio, Row} from "antd";
import {BranchesOutlined} from "@ant-design/icons";
import {redirectToGitlabAuth} from "../../../config/appConfig";
import * as React from "react";
import AzureDevModal from "./AzureDevModal";

const gutter = {xs: 8, sm: 16, md: 24, lg: 32}

const SelfHostedProviders = () => {
    return (
        <Row gutter={gutter} style={{marginTop: 10}}>
            <Col span={8}>
                <Button className="git-install" href={'/git/provider/private-gitlab/redirect'}>
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{minHeight: '100%'}}
                    >
                        <Col>
                            <img alt="gitlab" src="/icons/gitlab.png" width={100}/>
                        </Col>
                    </Row>
                </Button>
            </Col>
        </Row>
    )
}
const SaasProviders = () => {
    const [azuredevModal, setAzuredevModal] = useState(false);

    return (
        <Row gutter={gutter} style={{marginTop: 40}}>
            <Col span={8}>
                <Button className="git-install" href={window._env_.GITHUB_REDIRECT_URL}>
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{minHeight: '100%'}}
                    >
                        <Col>
                            <img alt="github" src="/icons/github.png" width={80}/>
                        </Col>
                    </Row>

                </Button>
            </Col>
            <Col span={8}>
                <Button className="git-install"
                        href={window._env_.BITBUCKET_REDIRECT_URL}>
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{minHeight: '100%'}}
                    >
                        <Col>
                            <img alt="bitbucket" src="/icons/bitbucket.png" width={120}/>
                        </Col>
                    </Row>

                </Button>
            </Col>
            <Col span={8}>
                <Button className="git-install" onClick={() => setAzuredevModal(true)}  >
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{minHeight: '100%'}}
                    >
                        <Col>
                            <img alt="azuredev" src="/icons/azure-devops.png" width={100}/>
                        </Col>
                    </Row>

                </Button>
                <AzureDevModal open={azuredevModal} onClose={() => setAzuredevModal(false)}  />
            </Col>
            <Col span={8}>
                <Button className="git-install" onClick={redirectToGitlabAuth}  >
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{minHeight: '100%'}}
                    >
                        <Col>
                            <img alt="gitlab" src="/icons/gitlab.png" width={100}/>
                        </Col>
                    </Row>
                </Button>
            </Col>

        </Row>
    )
}


export function GitModal({visible, onCancel}) {
    const [mode, setMode] = useState("public");

    const options = [
        {label: 'SaaS', value: 'public'},
        {label: 'Self-Hosted', value: 'private'},
    ];

    return (
        <Modal centered open={visible} className={"with-background"}
               onCancel={onCancel} width={700} footer={[]}>
            <div className="modal-background"></div>
            <div className="modal-icon"><BranchesOutlined/></div>
            <h2 style={{marginRight: 20, marginTop: -5}}>Add Git Provider</h2>
            <Card style={{marginBottom: 0, maxHeight: "70vh", overflow: "auto"}}>
                <p>We support Bitbucket, Github & Azure DevOps integration.</p>
                <div style={{minHeight: 310}}>
                    <Flex vertical gap="middle">
                        <Radio.Group
                            block
                            options={options}
                            value={mode}
                            onChange={(p) => setMode(p.target.value)}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Flex>
                    {mode === "public" ? <SaasProviders/> : <SelfHostedProviders/>}
                </div>
            </Card>
        </Modal>)
}

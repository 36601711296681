import {useParams, useSearchParams, useNavigate} from "react-router-dom";
import {useCallback, useContext, useEffect, useState, useRef} from "react";
import {UserContext} from "../../providers/UserProvider";
import AzureDevSetup from "../providerOnboarding/AzureSetupSteps"
import BlockingModal from "../dashboard/elements/PermissionModal";
import SetupGuide from "../onboarding/SetupGuide";
import {gitlabConfig} from "../../config/appConfig";


export default function GitProviderRedirect() {
    const navigate = useNavigate();
    const {backendService, iam, organisation, askReload} = useContext(UserContext);

    const {provider} = useParams();
    const [loading, setLoading] = useState(false);
    const [gitStatus, setGitStatus] = useState("loading");
    const [searchParams] = useSearchParams()
    const hasRun = useRef(false);

    const checkAccessRights = async values => {
        backendService.addAccessRights("git", {
            id: `${provider}-app`,
            resourceName: `${provider}-app`,
            provider: provider,
            resourceDetails: {
                ...paramsToObject(searchParams),
                ...values,
                manualPermissionCreation: values.manualPermissionCreation
            }
        }).then(() => {
            askReload(true);
            setGitStatus("linked");
            navigate("/dashboard/repos");
            setLoading(true)
        }).catch((e) => console.log(e))

    }


    const createAccessRight = useCallback(async (values) => {
        setLoading(true);
        try{
            await backendService.addAccessRights("git", {
                id: `${provider}-app`,
                resourceName: `${provider}-app`,
                provider: provider,
                resourceDetails: {
                    ...paramsToObject(searchParams),
                    ...values,
                }
            })
            askReload(true);
            setGitStatus("linked")
            navigate("/onboarding?scan=true");
        }catch(e){
            setGitStatus("error");
        }
    }, [searchParams, provider, backendService, navigate, askReload])

    useEffect(() => {
        if (!iam || loading || hasRun.current){
            return
        }
        hasRun.current = true;
        if (provider === "gitlab") {
            createAccessRight({
                redirectUri: gitlabConfig.redirectUri
            }).then()
        }else if (!["azuredev"].includes(provider)) {
            createAccessRight(undefined).then()
        }

    }, [provider, iam, loading, createAccessRight, searchParams])


    const params = paramsToObject(searchParams);
    if (params.error) {
        return <BlockingModal title={"Error: " + params.error} content={params.error_description}></BlockingModal>
    }

    if (provider === "azuredev") {
        return loading ?
            <SetupGuide status={gitStatus}/> : <AzureDevSetup apiKey={organisation?.apiKey} finish={checkAccessRights}/>
    }
    return <SetupGuide status={gitStatus}/>
}

function paramsToObject(entries) {
    const result = {}
    for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
}
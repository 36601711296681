import {Modal} from "antd";

export default function BlockingModal({title, content, onCancel = ()=>{}}) {
    return (
        <Modal centered title={title} open={true} onCancel={onCancel} width={600}
               footer={[]}>
            <p>{content}</p>
        </Modal>
    )


}

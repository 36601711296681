import {Alert, Space} from "antd";
import {UserContext} from "../../../providers/UserProvider";
import {useContext} from "react";
import * as React from "react";


function Alerts({hideButtons}) {
    const {organisation} = useContext(UserContext);
    if (!organisation?.repos || organisation?.repos.length === 0)
        return <Alert message="No Linked Repositories" type="warning" style={{marginBottom: 20, marginTop: 20}}
                      description={<><p>Install FirstMate on Github, AzureDevOps, Bitbucket or Gitlab to continue.</p>
                          {!hideButtons && <a className="white_link" href="/dashboard/repos">Link your
                              repositories</a>}</>} showIcon/>
}

export default function DashBoardHeader({title, hideAlerts, hideButtons, buttons}) {


    return (
        <div>
            <h1>{title}</h1>
            {!hideAlerts && <Alerts hideButtons={hideButtons}/>}
            <div style={{float: "right"}}>
                <Space size={[10, 0]} wrap>
                    {buttons}
                </Space>
            </div>
        </div>
    );

}

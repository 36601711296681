import {Button, Card, Col, Tag, Row, Space, Form, Input, Flex, Radio} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../providers/UserProvider";
import {FallOutlined} from "@ant-design/icons";
import AzureDevModal from "../dashboard/modals/AzureDevModal";
import {squircle} from 'ldrs'
import {useAuth0} from "@auth0/auth0-react";
import Confetti from "react-confetti";
import {useNavigate, useSearchParams} from "react-router-dom";
import {redirectToGitlabAuth} from "../../config/appConfig";
import RepoSelection from "../providerOnboarding/RepoSelection"
import PrivateGitlabOnboarding from "../providerOnboarding/PrivateGitlabOnboarding";

squircle.register()

const gutter = {xs: 8, sm: 16, md: 24, lg: 32}


export function GitSelection({onProviderSelection}) {
    const [azuredevModal, setAzuredevModal] = useState(false);
    const [mode, setMode] = useState("public");

    const options = [
        {label: 'SaaS', value: 'public'},
        {label: 'Self-Hosted', value: 'private'},
    ];

    function logGA(platform) {
        window.gtag('event', 'start_onboarding', {
            event_category: 'Onboarding',
            event_label: 'Start Onboarding',
            platform: platform,
        });
    }

    function azureDevStart() {
        logGA("azure");
        setAzuredevModal(true);
    }

    const SelfHostedProviders = () => {
        return (
            <Row gutter={gutter} style={{marginTop: 10}}>
                <Col span={24}>
                    <Button className="git-install"
                            onClick={() => onProviderSelection("private-gitlab")}>
                        <Row
                            type="flex"
                            justify="center"
                            align="middle"
                            style={{minHeight: '100%'}}
                        >
                            <Col>
                                <img alt="github" src="/icons/gitlab.png" width={60}/>
                            </Col>
                        </Row>

                    </Button>
                </Col>
            </Row>
        )
    }

    const SaasProviders = () => {
        return (
            <Row gutter={gutter} style={{marginTop: 10}}>
                <Col span={12}>
                    <Button className="git-install" onClick={() => logGA("github")}
                            href={window._env_.GITHUB_REDIRECT_URL}>
                        <Row
                            type="flex"
                            justify="center"
                            align="middle"
                            style={{minHeight: '100%'}}
                        >
                            <Col>
                                <img alt="github" src="/icons/github.png" width={100}/>
                            </Col>
                        </Row>

                    </Button>
                </Col>
                <Col span={12}>
                    <Button className="git-install"
                            onClick={() => logGA("bitbucket")}
                            href={window._env_.BITBUCKET_REDIRECT_URL}>
                        <Row
                            type="flex"
                            justify="center"
                            align="middle"
                            style={{minHeight: '100%'}}
                        >
                            <Col>
                                <img alt="bitbucket" src="/icons/bitbucket.png" width={160}/>
                            </Col>
                        </Row>

                    </Button>
                </Col>
                <Col span={12}>
                    <Button className="git-install" onClick={azureDevStart}>
                        <Row
                            type="flex"
                            justify="center"
                            align="middle"
                            style={{minHeight: '100%'}}
                        >
                            <Col>
                                <img alt="azuredev" src="/icons/azure-devops.png" width={130}/>
                            </Col>
                        </Row>
                    </Button>
                    <AzureDevModal open={azuredevModal} onClose={() => setAzuredevModal(false)}/>
                </Col>
                <Col span={12}>
                    <Button className="git-install" onClick={redirectToGitlabAuth}>
                        <Row
                            type="flex"
                            justify="center"
                            align="middle"
                            style={{minHeight: '100%'}}
                        >
                            <Col>
                                <img alt="gitlab" src="/icons/gitlab.png" width={60}/>
                            </Col>
                        </Row>
                    </Button>
                </Col>
            </Row>

        )

    }

    return (
        <div style={{minHeight: 310}}>
            <Flex vertical gap="middle">
                <Radio.Group
                    block
                    options={options}
                    value={mode}
                    onChange={(p) => setMode(p.target.value)}
                    optionType="button"
                    buttonStyle="solid"
                />
            </Flex>
            {mode === "public" ? <SaasProviders/> : <SelfHostedProviders/>}
        </div>

    )
}

function CreateUser() {
    const {initOrganisation} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const {user} = useAuth0();

    async function create(values) {
        setLoading(true);
        await initOrganisation({...values, email: user.email});
        setLoading(false);
    }

    return <Card className={"onboarding-card"}>
        <div className="modal-background"></div>
        <Space style={{position: "absolute", top: 85, right: 30}}></Space>
        <Card style={{textAlign: "left"}}>
            <div className="modal-icon" style={{marginBottom: 0, float: "left", marginRight: 20}}>
                <FallOutlined/></div>
            <h3 style={{width: 300, marginTop: 10}}>Create account</h3>
        </Card>
        <h3 style={{textAlign: "left"}}>Tell us who you are:</h3>
        <Form
            name="org-setup"
            layout="vertical"
            onFinish={create}
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            style={{maxWidth: 800, marginTop: 20}}
            autoComplete="off"
            initialValues={{
                // eslint-disable-next-line
                ["name"]: user.nickname

            }}
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{required: true, message: 'Please check you filled in your name'}]}>
                <Input placeholder="John Doe" style={{width: 300}}/>
            </Form.Item>
            <Form.Item
                label="Organisation"
                name="org"
                rules={[{required: true, message: 'Please provide an organisation name'}]}>
                <Input placeholder="your-org-name" style={{width: 300}}/>
            </Form.Item>
            <Button type="primary" form="org-setup" key="submit" htmlType="submit" loading={loading}>
                Next Step
            </Button>

        </Form>

    </Card>

}

function LinkGit({status}) {
    const {organisation, checkRepos, enableRepos} = useContext(UserContext);
    const [scanReady, setScanReady] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const scanning = searchParams.get("scan")


    const Status = () => {
        if (status === "error")
            return <Tag color="red" style={{position: "absolute", right: 20, top: 30}}>Error</Tag>
        if (!searchParams.get("scan"))
            return <Tag color="orange" style={{position: "absolute", right: 20, top: 30}}>In Progress</Tag>

        if (!scanReady)
            return <l-squircle
                style={{position: "absolute", right: 30, top: 23}}
                size={30}
                stroke="4"
                stroke-length="0.25"
                bg-opacity="0.2"
                speed="2"
                color="white"
            ></l-squircle>
        else return <Tag color="green" style={{position: "absolute", right: 20, top: 30}}>Done</Tag>

    }
    useEffect(() => {
        if (organisation?.repos?.length > 0) {
            setScanReady(true)
            if (organisation?.repos.filter(r => r.enabled).length > 0)
                navigate("/");
        }
    }, [organisation, navigate, searchParams])


    useEffect(() => {
        const intervalID = setInterval(() => {
            if (searchParams.get("scan") && !scanReady) {
                checkRepos()
            }
        }, 2000);

        return () => clearInterval(intervalID);
    }, [checkRepos, organisation, searchParams, scanReady]);

    const clickEnableRepos = async () => {
        try {
            setButtonLoading(true);
            await enableRepos(selected);
            navigate("/dashboard");
            checkRepos();
        } catch (e) {
            setButtonLoading(false);
            console.log(e);
        }
    }

    const RedirectLoading = () => {
        return (
            <Card style={{textAlign: "left"}}>
                <div className="modal-icon" style={{marginBottom: 0, float: "left", marginRight: 20}}>
                    <FallOutlined/></div>
                <h3 style={{width: 350, marginTop: 10}}>Connecting to your git provider...</h3>
                <Status/>
            </Card>
        )

    }

    const SetupCard = () => {
        const [provider, setProvider] = useState("");
        if (provider === "private-gitlab") {
            return <>
                <Card style={{textAlign: "left"}}>
                    <div className="modal-icon" style={{marginBottom: 0, float: "left", marginRight: 20}}>
                        <FallOutlined/></div>
                    <PrivateGitlabOnboarding/>
                </Card>
            </>

        }
        return <>
            <Card style={{textAlign: "left"}}>
                <div className="modal-icon" style={{marginBottom: 0, float: "left", marginRight: 20}}>
                    <FallOutlined/></div>
                <h3 style={{
                    width: 350,
                    marginTop: 10
                }}>{!scanReady ? "Link your Git provider" : "Select repositories"}</h3>
                <p>{scanReady && `You selected ${selected.length} repositories`}</p>
            </Card>
            {!scanReady && <GitSelection onProviderSelection={setProvider} style={{minHeight: 1000}}/>}
        </>

    }

    const repos = organisation.repos.map(repo => ({key: repo.id, name: repo.name}))

    return <Card className={"onboarding-card"}>
        {scanReady && <Confetti
            style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0}}
            colors={["#FF99FF", "#AABBFF", "#FFD700"]}
            numberOfPieces={300}
            gravity={0.15}
            recycle={false}
        />}
        <div className="modal-background2"></div>
        <Space style={{position: "absolute", top: 85, right: 30}}></Space>
        {(status || scanning) ? <RedirectLoading/> : <SetupCard/>}
        {scanReady &&
            <><RepoSelection repos={repos} setSelected={setSelected}/>
                <Button loading={buttonLoading} disabled={selected.length < 1} type={"primary"}
                        style={{visibility: scanReady ? "visible" : "hidden"}}
                        onClick={clickEnableRepos}>
                    Start
                    Using
                    FirstMate</Button></>}


    </Card>

}

export default function SetupGuide({status}) {
    const {organisation, logOut} = useContext(UserContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (!organisation)
            return
        if (organisation.repos?.length > 0 && organisation.repos.filter(r => r.enabled).length > 0) {
            navigate("/dashboard");
        }
    }, [navigate, organisation]);


    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#161B26"
        }}>
            <p style={{position: "absolute", top: 10, right: 10, cursor: "pointer"}} onClick={logOut}>Log Out</p>
            <img alt="logo" src={"/logo.png"} style={{position: "absolute", width: 200, top: 10, left: 10}}/>
            <div style={{textAlign: "center"}}>
                <h1>Setup your account</h1>
                <p style={{marginTop: 10}}>You're almost ready to activate faster & better pull requests.
                    It only takes 2 clicks.</p>
                {organisation ? <LinkGit status={status}/> :
                    <CreateUser/>}
            </div>
            <Button style={{position: "absolute", bottom: 15, left: 10}} target="_blank" rel="noopener noreferrer"
                    href={"https://join.slack.com/t/firstmatecommunity/shared_invite/zt-2u9f0yw5e-Av1~asi1XbJQvZ2d4mhyMg"}>Need
                support? Join our Slack community</Button>
        </div>
    );
}
import * as React from "react";
import {Button, Card, Col, Row} from "antd";
import DashBoardHeader from "../elements/Header";
import {useContext, useState} from "react";
import {UserContext} from "../../../providers/UserProvider";
import {GitModal} from "../modals/GitModal";
import {DocProviderModal} from "../modals/DocProviderModal";
import {SupportProviderModal} from "../modals/SupportProviderModal";
import {ChatProviderModal} from "../modals/ChatProviderModal";


const gitProviderImageMapper = {
    "gitlab": <img alt="github" src="/icons/gitlab.png" width={60}/>,
    "private-gitlab": <img alt="github" src="/icons/gitlab.png" width={60}/>,
    "bitbucket": <img alt="github" src="/icons/bitbucket.png" width={60}/>,
    "github": <img alt="github" src="/icons/github.png" width={60}/>,
    "azuredev": <img alt="github" src="/icons/azure-devops.png" width={60}/>,
}
const documentationProviderImageMapper = {
    "confluence": <img alt="confluence" src="/icons/conlfuence-logo.png" width={60}/>
}
const chatProviderImageMapper = {
    "slack": <img alt="slack" src="/icons/slack-logo.png" width={60}/>
}

const supportProviderImageMapper = {
    "jira": <img alt="slack" src="/icons/slack-logo.png" width={60}/>
}

export default function Home() {
    const [openGitModal, setOpenGitModal] = useState(false);
    const [openDocModal, setOpenDocModal] = useState(false);
    const [openSupportModal, setOpenSupportModal] = useState(false);
    const [openChatModal, setOpenChatModal] = useState(false);
    const {organisation} = useContext(UserContext);

    return (
        <div>
            <DashBoardHeader title={"Welcome to FirstMate"} hideAlerts={false}/>
            <Row gutter={16} className="p-6">
                {/* Git Provider Selection */}
                <Col span={10}>
                    <Card title="Connected git Providers">
                        <Button onClick={()=> setOpenGitModal(true)}>Add</Button>
                        <GitModal visible={openGitModal} onCancel={()=> setOpenGitModal(false)} />
                        {organisation.accessRights?.gitProviders.map((item) => (
                            <Card title={item.provider} className={"flex justify-center items-center text-center"}>
                                {gitProviderImageMapper[item.provider]}
                            </Card>
                        ))}
                    </Card>
                </Col>

                {/* FirstMate Static Box */}
                <Col span={4} className="flex justify-center items-center">
                    <Card className="text-center">
                        <img alt="logo" src="/logo.png" style={{width: "15em"}}/>
                    </Card>

                </Col>

                {/* Output Providers Selection */}
                <Col span={10}>
                    <Card title="Connected document providers">
                        <Button onClick={()=> setOpenDocModal(true)}>Add</Button>
                        <DocProviderModal visible={openDocModal} onCancel={()=> setOpenDocModal(false)} />
                        {organisation.accessRights?.documentProviders?.map((item) => (
                            <Card title={item.provider} className={"flex justify-center items-center text-center"}>
                                {documentationProviderImageMapper[item.provider]}
                            </Card>
                        ))}
                    </Card>
                    <Card title="Connected chat providers">
                        <Button onClick={()=> setOpenChatModal(true)}>Add</Button>
                        <ChatProviderModal visible={openChatModal} onCancel={()=> setOpenChatModal(false)} />
                        {organisation.accessRights?.chatProviders?.map((item) => (
                            <Card title={item.provider} className={"flex justify-center items-center text-center"}>
                                {chatProviderImageMapper[item.provider]}
                            </Card>
                        ))}
                    </Card>
                    <Card title="Connected ticket providers">
                        <Button onClick={()=> setOpenSupportModal(true)}>Add</Button>
                        <SupportProviderModal visible={openSupportModal} onCancel={()=> setOpenSupportModal(false)} />
                        {organisation.accessRights?.supportProviders?.map((item) => (
                            <Card title={item.provider} className={"flex justify-center items-center text-center"}>
                                {supportProviderImageMapper[item.provider]}
                            </Card>
                        ))}
                    </Card>
                </Col>
            </Row>
        </div>
    );


}

import {Card, Modal, Row} from "antd";
import {BranchesOutlined} from "@ant-design/icons";
import * as React from "react";

const gutter = {xs: 8, sm: 16, md: 24, lg: 32}

export function SupportProviderModal({visible, onCancel}) {


    return (
        <Modal centered open={visible} className={"with-background"}
               onCancel={onCancel} width={700} footer={[]}>
            <div className="modal-background"></div>
            <div className="modal-icon"><BranchesOutlined/></div>
            <h2 style={{marginRight: 20, marginTop: -5}}>Add support provider</h2>
            <Card title={"Chat providers"} style={{marginBottom: 0, maxHeight: "70vh", overflow: "auto"}}>
                <Row gutter={gutter}>
                    <p>No ticket providers are currently available, but we're actively working on expanding our
                        integrations. Stay tuned!</p>
                    {/*<Col span={8}>*/}
                    {/*    <Button className="git-install" href={window._env_.GITHUB_REDIRECT_URL}>*/}
                    {/*        <Row*/}
                    {/*            type="flex"*/}
                    {/*            justify="center"*/}
                    {/*            align="middle"*/}
                    {/*            style={{minHeight: '100%'}}*/}
                    {/*        >*/}
                    {/*            <Col>*/}
                    {/*                <img alt="confluence" src="/icons/conlfuence-logo.png" width={80}/>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*    </Button>*/}
                    {/*</Col>*/}
                </Row>
            </Card>
        </Modal>)
}
